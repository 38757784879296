import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutInstructors from "../components/Courses/AboutInstructors"

const Instructor = ({ data }) => {
  console.log("data>>", data)
  return (
    <Layout>
      <SEO title="LifeatEducorp" />
      <AboutInstructors
        instructors={{ instructors: { edges: "hello" } }}
      ></AboutInstructors>
    </Layout>
  )
}
// export const data = graphql`
//   query {
//     instructors: allContentfulBrainyFiedInstructors {
//       edges {
//         node {
//           experience
//           name
//           technologies
//           image {
//             fluid {
//               src
//             }
//           }
//         }
//       }
//     }
//   }
// `
export default Instructor
